
    // ONLY IMPORT FILES THAT CONTAIN SCSS VARIABLES ONLY, and nothing else.
    @import "src/styles/variables.scss";
  
$modal-header-height: 48px;

.portal {
  :global(.mantine-Paper-root) {
    border-start-start-radius: var(--modal-radius, var(--mantine-radius-default));
    border-start-end-radius: var(--modal-radius, var(--mantine-radius-default));
  }

  @include media-breakpoint-down(md) {
    :global(.mantine-Paper-root),
    :global(.mantine-Modal-header) {
      border-start-start-radius: 0;
      border-start-end-radius: 0;
    }
  }

  :global(.mantine-Modal-header) {
    min-height: $modal-header-height;
    background-color: $color-rainforest-primary;
    color: $color-accent-fresh;
    padding-top: $space-xs;
    padding-bottom: $space-xs;
  }

  :global(.mantine-Modal-title) {
    flex: none;
    font-weight: bold;
  }

  .headerStartButton,
  :global(.mantine-Modal-close) {
    position: absolute;
    top: 0;
    width: $modal-header-height;
    height: $modal-header-height;
    background-color: $color-accent-fresh30;
    text-align: center;
    color: $color-accent-fresh;
  }

  .headerStartButton {
    left: 0;
    border: 0;
    border-radius: 0;

    :global(.mantine-Button-label) {
      overflow: unset;
    }
  }

  .headerStartButton + :global(.mantine-Modal-title) {
    margin-left: $modal-header-height;
  }

  :global(.mantine-Modal-close) {
    right: 0;
  }

  :global(.mantine-Modal-body) {
    position: relative;
    padding: 0;
  }
}

.body {
  padding: var(--mb-padding, var(--mantine-spacing-md));
  padding-top: var(--mb-padding, var(--mantine-spacing-md));

  &,
  & > div {
    & > p:first-child {
      margin-top: 0;
    }

    & > p:last-child {
      margin-bottom: 0;
    }
  }
}

.footer {
  & > * {
    margin: 0 !important;
    border-radius: 0;
  }
}
